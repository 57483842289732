import ApplicationController from './application_controller'
const TinyMDE = require('tiny-markdown-editor')

export default class extends ApplicationController {

  static targets = ['textArea', 'commandBar']

  static values = {
    commandBar: {
      type: Array,
      default: [
        'bold', 'italic', 'strikethrough',
        '|',
        'h1', 'h2',
        '|',
        'ul', 'ol',
        '|',
        'blockquote', 'hr'
      ]
    }
  }

  connect () {
    if (this.hasTextAreaTarget) {
      this.editor = new TinyMDE.Editor({
        textarea: this.textAreaTarget
      })

      if (this.hasCommandBarTarget) {
        this.commandBar = new TinyMDE.CommandBar({
          element:  this.commandBarTarget,
          editor:   this.editor,
          commands: this.commandBarValue
        })
      }
    }
  }
}
