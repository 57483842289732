import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    embedUrl: String
  }

  static targets = [
    'language',
    'layout',
    'position',
    'variant',
    'embedCode',
    'floatingPositionWrapper',
    'preview'
  ]

  get isFloating() {
    return this.hasLayoutTarget && this.layoutTarget.value == 'layout=floating'
  }

  connect() {
    this.update()
  }

  disconnect() {
    if (this.hasPreviewTarget) {
      this.previewTarget.innerHTML = ''
    }
  }

  update() {
    this.updateVisibleInputs()
    this.updatePreview()
    this.updateEmbedCode()
  }

  updateVisibleInputs() {
    if (this.hasFloatingPositionWrapperTarget) {
      if (this.isFloating) {
        this.floatingPositionWrapperTarget.classList.remove('is-hidden')
      } else {
        this.floatingPositionWrapperTarget.classList.add('is-hidden')
      }
    }
  }

  updatePreview() {
    if (this.hasPreviewTarget) {
      this.previewTarget.innerHTML = ''
      let scriptTag = document.createElement('script')
      scriptTag.type = 'text/javascript'
      scriptTag.src  = this.generateScriptURL(['hide_popover=true'])
      this.previewTarget.appendChild(scriptTag)
    }
  }

  updateEmbedCode() {
    if (this.hasEmbedCodeTarget) {
      let newEmbedCode = this.generateEmbedCode()

      let hrefAttributeElement = Array.from(
        this.embedCodeTarget.querySelectorAll('.s')
      ).find((element) => element.innerText.includes('new.js'))

      if (hrefAttributeElement) {
        hrefAttributeElement.innerText = '"' + this.generateScriptURL() + '"'
      } else {
        this.embedCodeTarget.innerText = newEmbedCode
      }

      this.embedCodeTarget.querySelectorAll('[data-clipboard-copy-value]').forEach(
        (copyButton) => copyButton.dataset.clipboardCopyValue = newEmbedCode
      )
    }
  }

  generateScriptURL(params = []) {
    let scriptURL = this.embedUrlValue

    if (this.hasLanguageTarget) {
      params.push(this.languageTarget.value)
    }

    if (this.hasLayoutTarget) {
      params.push(this.layoutTarget.value)
    }

    if (this.hasVariantTarget) {
      params.push(this.variantTarget.value)
    }

    if (this.isFloating) {
      if (this.hasPositionTarget) {
        params.push(this.positionTarget.value)
      }
    }

    params = params.filter((param) => param.length >= 1)

    if (params.length) {
      if (scriptURL.indexOf('?') >= 0) {
        scriptURL = `${scriptURL}&${params.join('&')}`
      } else {
        scriptURL = `${scriptURL}?${params.join('&')}`
      }
    }

    return scriptURL
  }

  generateEmbedCode() {
    return `<script type="text/javascript" src="${this.generateScriptURL()}"></script>`
  }

}
