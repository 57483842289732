import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    embedUrl: String
  }

  static targets = ['preview']

  connect () {
    this.popoverReady = this.popoverReady.bind(this)
    this.popoverReadyEventListener = window.addEventListener('reservation-widget-popover-ready', this.popoverReady)
  }

  disconnect () {
    window.removeEventListener('reservation-widget-popover-ready', this.popoverReady)
  }

  popoverReady (event) {
    if (event && event.detail && event.detail.reservationPopover) {
      let popover = event.detail.reservationPopover
      popover.open()
    }
  }

  previewPopover (event) {
    if (event) {
      event.preventDefault()

      if (this.hasPreviewTarget && event.params && event.params.popoverId) {
        this.previewTarget.innerHTML = ''

        let scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.src  = this.generateScriptURL(event.params.popoverId)
        this.previewTarget.appendChild(scriptTag)
      }
    }
  }

  generateScriptURL(popoverId) {
    let scriptURL = this.embedUrlValue
    let params  = []

    params.push('hide_widget=true')
    params.push(`widget_popover_id=${popoverId}`)

    params = params.filter((param) => param.length >= 1)

    if (params.length) {
      if (scriptURL.indexOf('?') >= 0) {
        scriptURL = `${scriptURL}&${params.join('&')}`
      } else {
        scriptURL = `${scriptURL}?${params.join('&')}`
      }
    }

    return scriptURL
  }
}
