import ApplicationController from 'controllers/application_controller'
import Cookies from 'vendor/js.cookie'

export default class extends ApplicationController {
  static values = {
    cookieName: { type: String, default: 'element-visibility' }
  }
  
  static targets = [
    'hideableElement'
  ]

  static classes = [
    'isHidden'
  ]

  get isHidden () {
    return this.hasHideableElementTarget && this.hideableElementTarget.classList.contains(this.isHiddenClass)
  }

  get cookie() {
    return this.getCookie()
  }

  set cookie(newSetting) {
    this.setCookie(newSetting)
  }

  connect() {
    if (this.cookie == 'hide') {
      this.hide()
    } else {
      this.show()
    }
  }

  toggle(event) {
    if (event) {
      event.preventDefault()
    }
    
    if (this.isHidden) {
      this.show(event)
    } else {
      this.hide(event)
    }
  }

  show () {
    this.cookie = 'show'

    if (this.hasHideableElementTarget) {
      this.hideableElementTargets.forEach((target) => target.classList.remove(this.isHiddenClass))
    }
  }

  hide () {
    this.cookie = 'hide'

    if (this.hasHideableElementTarget) {
      this.hideableElementTargets.forEach((target) => target.classList.add(this.isHiddenClass))
    }
  }

  // PRIVATE
  
    setCookie(setting) {
      Cookies.set(this.cookieNameValue, setting)
    }
  
    getCookie() {
      var setting = Cookies.get(this.cookieNameValue)
  
      if (setting != 'hide' && setting != 'show') {
        this.setCookie(setting = 'hide')
      }
  
      return setting
    }
}