import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'navigationList'
  ]

  static classes = [
    'isHidden',
    'itemsHidden'
  ]

  connect () {
    window.dispatchEvent(new Event('resize'))
    this.removeItems()
  }

  removeItems () {
    const navigationItems = this.navigationListTarget.querySelectorAll('li')
    const navigationWidth = this.navigationListTarget.offsetWidth

    let navigationItemsWidth = 0
    let itemsHidden          = false

    navigationItems.forEach(item => {
      item.classList.remove(this.isHiddenClass)
      navigationItemsWidth += item.offsetWidth

      if (navigationItemsWidth > navigationWidth) {
        item.classList.add(this.isHiddenClass)
        itemsHidden = true
      }
    })

    this.element.classList.toggle(this.itemsHiddenClass, itemsHidden)
  }
}